import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../config/config.js";
import "./ToggleButton.css"; // Pour les styles CSS

let latestMessage = "";
let isOn = false;

const ToggleButton = ({ clientID, deviceID, webSocket, socketMessage }) => {
  

  if (
    socketMessage.clientID === clientID &&
    socketMessage.pins &&
    socketMessage.pins[deviceID]
  ) {
   isOn = socketMessage.pins[deviceID].state === 1;
  }

  console.log('=== isOn === ToggleButton.js === key: 544603 ===');
  console.dir(isOn, { depth: null, colors: true })
  console.log('=================================');

  //socketConnect(setIsOn);

  const toggle = async () => {
    // const message = isOn ? 'off' : 'on';
    const data = {
      clientID,
      deviceID,
      type:"toggle",

    }
    try {
      const response = await axios.post(
        `/send-message?clientID=${clientID}`,data
      );
      console.log("=== response === ToggleButton.js === key: 268436 ===");
      console.dir(response.data, { depth: null, colors: true });
      console.log("=================================");
      //setIsOn(response.data.message === "on");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h2>{!isOn ? "The device is turned off" : "The device is turned on"}</h2>
      <button
        className={`toggle-button ${!isOn ? "on" : "off"}`}
        onClick={toggle}
      >
        {!isOn ? "TURN ON" : "SHUTDOWN"}
      </button>
    </>
  );
};

export default ToggleButton;
