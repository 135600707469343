import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../config/config.js";
import ToggleButton from "./ToggleButton.js";
import TimeRangeManager from "./TimeRangeManager.js";

let clientID;

const DeviceManager = (props) => {
  return (
    <div className={props.className}>
      <h1>
        {props.deviceName} (id: {props.deviceID})
      </h1>
      <ToggleButton
        clientID={props.clientID}
        deviceID={props.deviceID}
        webSocket={props.webSocket}
        socketMessage={props.socketMessage}
      />
      <TimeRangeManager clientID={props.clientID} deviceID={props.deviceID} timeRanges={props.timeRanges} />
    </div>
  );
};
export default DeviceManager;
