//path: src/config/config.js
//import: import Config from "./src/config/config.js";
export default class Config{
    static get isInProd(){
        return process.env.NODE_ENV === 'production';
    }
    static get port(){
        return process.env.REACT_APP_SERVER_PORT;
    }
    static get baseURL(){
        return this.isInProd ? process.env.REACT_APP_REMOTE_URL : process.env.REACT_APP_LOCAL_URL+":"+this.port;
    }
    static get domain(){
        return this.isInProd ? process.env.REACT_APP_REMOTE_DOMAIN : process.env.REACT_APP_LOCAL_DOMAIN+":"+this.port;
    }
    static get clientID(){
        return process.env.REACT_APP_CLIENT_ID;
    }
    static get webSocketProtocol(){
        return this.isInProd ? "wss" : "ws";
    }
}