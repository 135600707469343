import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../../config/config.js";
import "./TimeRangeManager.css";

let scheduleIsLoaded = false;

const clientID = Config.clientID;

function TimeRangeManager(props) {
  const [timeRanges, setTimeRanges] = useState([]);
  const [error, setError] = useState("");
  const [deployed, setDeployed] = useState(true);
  const [scheduleIsLoaded, setScheduleIsLoaded] = useState(false);

  if (props.timeRanges && scheduleIsLoaded === false) {
    setScheduleIsLoaded(true);
    setTimeRanges(props.timeRanges);
  }

  const minutesToHHMM = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}`;
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const deployData = async () => {
    const open = [];
    const shutdown = [];

    timeRanges.forEach((range, index) => {
      const startMinutes = timeToMinutes(range.start);
      const endMinutes = timeToMinutes(range.end);

      // Open command at the start of the range
      open.push({ minute: startMinutes });

      // Shutdown command at the end of the range
      shutdown.push({ minute: endMinutes });
    });

    const schedule = { open, shutdown };

    axios
      .post(
        `/schedule?clientID=${Config.clientID}&deviceID=${props.deviceID}`,
        { schedule }
      )
      .then((response) => {
        console.log("Deploy successful:", response.data);
        setDeployed(true);
      })
      .catch((error) => {
        console.error("Deploy failed:", error);
        setError("Deploy failed. Please try again.");
      });
  };

  const addTimeRange = (start, end) => {
    setError("");
    setDeployed(false); // Reset deploy status when modifying the list
    if (start >= end) {
      setError("L'heure de début doit être antérieure à l'heure de fin.");
      return;
    }
    for (let range of timeRanges) {
      if (start < range.end && end > range.start) {
        setError("Les plages horaires ne doivent pas se chevaucher.");
        return;
      }
    }
    setTimeRanges(
      [...timeRanges, { start, end }].sort((a, b) =>
        a.start.localeCompare(b.start)
      )
    );
  };

  const deleteTimeRange = (index) => {
    setTimeRanges(timeRanges.filter((_, i) => i !== index));
    setDeployed(false);
  };

  const resetTimeRanges = () => {
    setTimeRanges([]);
    setError("");
    setDeployed(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { start, end } = event.target.elements;
    addTimeRange(start.value, end.value);
  };

  return (
    <div className="time-range-manager">
      
      <ul className="time-range-list">
        {timeRanges.map((range, index) => (
          <li key={index}>
            De {range.start} à {range.end}
            <button
              onClick={() => deleteTimeRange(index)}
              className="btn delete"
            >
              Supprimer
            </button>
          </li>
        ))}
      </ul>
      <form onSubmit={handleSubmit} className="time-range-form">
        <label>
          Heure de début:
          <input type="time" name="start" required />
        </label>
        <label>
          Heure de fin:
          <input type="time" name="end" required />
        </label>
        <button type="submit" className="btn add">
          Ajouter
        </button>
      </form>
      {error && <div className="error-message">{error}</div>}
      <button onClick={resetTimeRanges} className="btn reset">
        Reset
      </button>
      <button
        onClick={deployData}
        className={`btn deploy ${deployed ? "deployed" : ""}`}
      >
        {deployed ? "Deployed" : "Deploy"}
      </button>
    </div>
  );
}

export default TimeRangeManager;
