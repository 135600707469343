import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../config/config.js";
import DeviceManager from "./components/DeviceManager.js"; // Replace '../path/to/DeviceManager' with the actual path to the DeviceManager component.
import "./Dashboard.css";

let ws;
let timeout;
let extSetWebSocket;
let extSetSocketMessage;

let clientID;

const socketConnect = () => {
  console.log("=== ws?.readyState === Dashboard.js === key: 488263 ===");
  console.dir(ws?.readyState, { depth: null, colors: true });
  console.dir(WebSocket.OPEN, { depth: null, colors: true });
  console.dir(clientID, { depth: null, colors: true });
  console.log("=================================");
  try {
    if (ws?.readyState !== WebSocket.OPEN) {
      const wsurl = `${Config.webSocketProtocol}://${Config.domain}/ws_browser?clientID=${clientID}`;
      console.log("=== wsurl === Dashboard.js === key: 714199 ===");
      console.dir(wsurl, { depth: null, colors: true });
      console.log("=================================");
      ws = new WebSocket(
        `${Config.webSocketProtocol}://${Config.domain}/ws_browser?clientID=${clientID}`
      );
    }
  } catch (error) {
    reconnect();
    return;
  }

  ws.onopen = function (event) {
    extSetWebSocket(ws);
    console.log("Connection opened");
  };

  ws.onmessage = function (event) {
    let txt = event.data;
    if (event.data instanceof Blob) {
      // The data is a Blob instance
      const reader = new FileReader();

      reader.onload = function () {
        txt = reader.result;
      };

      reader.onerror = function (error) {
        console.error("Error reading blob: ", error);
      };

      reader.readAsText(event.data); // Read the blob as text
    } else {
      // Handle other types of messages
    }

    console.log("Message from server: ", txt);

    try {
      const message = JSON.parse(txt);
      console.log("=== message === Dashboard.js === key: 864168 ===");
      console.dir(message, { depth: null, colors: true });
      console.log("=================================");
      if (message.clientID === clientID) {
        extSetSocketMessage(message);
      }
    } catch (error) {
      console.error("Error parsing message:", error);
    }
  };

  ws.onerror = function (event) {
    console.error("WebSocket error observed:", event);
    extSetWebSocket(null);
    reconnect();
    return;
  };

  ws.onclose = function (event) {
    console.log("Connection closed");
    extSetWebSocket(null);
    reconnect();
    return;
  };

  return ws;
};

const reconnect = () => {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    socketConnect();
  }, 5000);
};

const minutesToHHMM = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;
};

const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

const getStatus = async () => {
  try {
    const response = await axios.get(`/status?clientID=${clientID}`);
    console.log("=== response.data === Dashboard.js === key: 199842 ===");
    console.dir(response.data, { depth: null, colors: true });
    console.log("=================================");
    return response.data;
  } catch (error) {
    console.error("Error fetching status:", error);
    return {};
  }
};

const Dashbord = (props) => {
  const [timeRanges, setTimeRanges] = useState({});
  const [error, setError] = useState("");
  const [webSocket, setWebSocket] = useState(null);
  const [socketMessage, setSocketMessage] = useState("");

  extSetWebSocket = setWebSocket;
  extSetSocketMessage = setSocketMessage;

  clientID = !clientID ? props.clientID : clientID;

  useEffect(() => {
    // Fonction pour charger les plages horaires au démarrage du composant
    const loadSchedule = () => {
      axios
        .get(`/schedule?clientID=${Config.clientID}`)
        .then((response) => {
          console.log("=== response.data === Dashboard.js === key: 658518 ===");
          console.dir(response.data, { depth: null, colors: true });
          console.log("=================================");
          const schedules = {};
          const devices = response.data.devices;
          for (let key in devices) {
            const { open, shutdown } = devices[key];
            if (open.length !== shutdown.length) {
              console.log("Mismatch in schedule data: " + key);
              continue;
            }
            const ranges = open.map((item, index) => {
              return {
                start: minutesToHHMM(item.minute),
                end: minutesToHHMM(shutdown[index].minute),
              };
            });
            schedules[key] = ranges;
          }

          console.log('=== schedules === Dashboard.js === key: 972312 ===');
          console.dir(schedules, { depth: null, colors: true })
          console.log('=================================');

          setTimeRanges(schedules);
        })
        .catch((error) => {
          console.error("Error fetching schedule:", error);
          setError("Failed to load initial schedule");
        });
    };

    loadSchedule();
  }, []);

  useEffect(() => {
    // Fonction pour charger les plages horaires au démarrage du composant
    // setTimeRanges(props.timeRanges||[]);
    socketConnect();
    getStatus();
  }, []);

  return (
    //
    <div className="dashboard">
      {webSocket == null ? (
        <h1>Connecting...</h1>
      ) : (
        <>
          <DeviceManager
            className="device-manager"
            deviceName="Climatisation"
            clientID={clientID}
            deviceID="r1"
            timeRanges={timeRanges["r1"]}
            webSocket={webSocket}
            socketMessage={socketMessage}
          />
          <DeviceManager
            className="device-manager"
            deviceName="Ecran vitrine"
            clientID={clientID}
            deviceID="r2"
            timeRanges={timeRanges["r2"]}
            webSocket={webSocket}
            socketMessage={socketMessage}
          />
          <DeviceManager
            className="device-manager"
            deviceName="Eclairage entrée"
            clientID={clientID}
            deviceID="r3"
            timeRanges={timeRanges["r3"]}
            webSocket={webSocket}
            socketMessage={socketMessage}
          />
          <DeviceManager
            className="device-manager"
            deviceName="Eclairage arrière"
            clientID={clientID}
            deviceID="r4"
            timeRanges={timeRanges["r4"]}
            webSocket={webSocket}
            socketMessage={socketMessage}
          />
        </>
      )}
    </div>
  );
};

export default Dashbord;
