
import React, { useState, useEffect } from "react";
//import ToggleButton from './pages/components/ToggleButton.js';
//import TimeRangeManager from './pages/components/TimeRangeManager.js';
import './App.css';
//import Dashboard from './pages/components/Dashboard.js';
import Config from './config/config.js'; // Import the Config object
import Dashbord from "./pages/Dashboard.js";



const clientID = Config.clientID;



const App = () => {

  
  return (
    // {webSocket == null ? <h1>Connecting...</h1> : <Dashboard clientID={clientID} webSocket={ws}/>}
    <div className="app">
    
      <Dashbord clientID={clientID} />
    
    </div>
  );
}

export default App;
